import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { addNotations } from './components/addNotations';
import { addProjects } from './components/addProjects';
import { displayCount } from './components/displayCount';

displayCount(); 
addProjects(); 
addNotations();

